import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ErrorBoundary } from "@/components/error";
import { ENV } from "@/lib/constants";

import { AppWrapper } from "./pages/_app";
import { OnboardingView } from "./pages/onboarding/OnboardingView";

const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
	{
		element: <AppWrapper />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				children: [
					// Onboarding page (dev only)
					ENV !== "prod" ? { path: "/", element: <OnboardingView /> } : {},
					// Auth callback pages
					{
						path: "/alfa-auth/:guid",
						lazy: () => import("./pages/auth"),
					},
					{
						path: "/alfa/id",
						lazy: () => import("./pages/alfa-id"),
					},
					// Promo pages
					{
						path: "/alfa_recommends_24_29_05_24",
						lazy: () =>
							import("./pages/alfa_recommends_24_29_05_24/AlfaFriday"),
					},
					{
						path: "/alfa_recommends_24_29_06_24",
						lazy: () =>
							import("./pages/alfa_recommends_24_29_06_24/AlfaFriday"),
					},
				],
			},
			{
				lazy: () => import("./pages/_profile"),
				// @ts-ignore
				shouldRevalidate: ({ nextUrl }) => nextUrl.pathname === "/checkout",
				children: [
					{
						lazy: () => import("./pages/_cities"),
						shouldRevalidate: () => false,
						children: [
							// Set city page
							{
								path: "/city",
								lazy: () => import("./pages/cities"),
							},
							// Account page
							{
								path: "/account",
								lazy: () => import("./pages/account"),
							},
							{
								lazy: () => import("./pages/_actions"),
								// @ts-ignore
								shouldRevalidate: ({ currentParams, nextParams }) => {
									return currentParams.cid !== nextParams.cid;
								},
								children: [
									// Action pages
									{
										path: "/city/:cid",
										lazy: () => import("./pages/city"),
										// @ts-ignore
										shouldRevalidate: ({ currentParams, nextParams }) => {
											return currentParams.cid !== nextParams.cid;
										},
									},
									// Selections pages
									{
										path: "/city/:cid/selections/:sid",
										lazy: () => import("./pages/selections"),
									},
									{
										path: "/city/:cid/date",
										lazy: () => import("./pages/date/DateView"),
									},
									// Filters
									{
										path: "/city/:cid/search",
										lazy: () => import("./pages/search"),
									},
									{
										path: "/city/:cid/filters",
										lazy: async () => {
											const { FiltersView } = await import("./pages/filters");
											return { Component: FiltersView };
										},
									},
									{
										path: "/city/:cid/filters/city",
										lazy: async () => {
											const { FiltersCityView } = await import(
												"./pages/filters"
											);
											return { Component: FiltersCityView };
										},
									},
									{
										path: "/city/:cid/filters/genres",
										lazy: async () => {
											const { FiltersGenresView } = await import(
												"./pages/filters"
											);
											return { Component: FiltersGenresView };
										},
									},
									{
										path: "/city/:cid/filters/venues",
										lazy: async () => {
											const { FiltersVenuesView } = await import(
												"./pages/filters"
											);
											return { Component: FiltersVenuesView };
										},
									},
									{
										path: "/city/:cid/filters/date",
										lazy: async () => {
											const { FiltersDateView } = await import(
												"./pages/filters"
											);
											return { Component: FiltersDateView };
										},
									},
									{
										id: "action",
										lazy: async () => {
											const { loader } = await import("./pages/action");
											return { loader };
										},
										// @ts-ignore
										shouldRevalidate: ({ currentParams, nextParams }) => {
											return currentParams.action !== nextParams.action;
										},
										children: [
											{
												path: "/city/:cid/:action",
												lazy: async () => {
													const { Component } = await import("./pages/action");
													return { Component };
												},
											},
											{
												path: "/city/:cid/:action/reservation/:eventId",
												lazy: () => import("./pages/reservation"),
											},
										],
									},
								],
							},
							// Checkout page (create order)
							{
								path: "/checkout",
								lazy: () => import("./pages/checkout"),
							},
							// Info pages
							{
								path: "/faq",
								lazy: () => import("./pages/faq/FAQView"),
							},
							{
								path: "/docs",
								lazy: () => import("./pages/docs/DocsView"),
							},
							// My orders page
							{
								path: "/orders",
								lazy: () => import("./pages/orders"),
							},
							{
								id: "tickets",
								lazy: async () => {
									const { loader } = await import("./pages/tickets");
									return { loader };
								},
								shouldRevalidate: () => false,
								children: [
									// Ticket page
									{
										path: "/orders/:oid",
										lazy: async () => {
											const { Component } = await import("./pages/tickets");
											return { Component };
										},
									},
									// Refund pages
									{
										path: "/orders/:oid/refund",
										lazy: () => import("./pages/refund/RefundView"),
									},
									{
										path: "/orders/:oid/refund-reason",
										lazy: () =>
											import("./pages/refund-reason/RefundReasonView"),
									},
									{
										path: "/orders/:oid/automatic-refund",
										lazy: () =>
											import("./pages/automatic-refund/AutomaticRefundView"),
									},
									{
										path: "/orders/:oid/manual-refund",
										lazy: () =>
											import("./pages/manual-refund/ManualRefundView"),
									},
								],
							},
							// Success payment callback page
							{
								path: "/success/:oid/:uid",
								lazy: () => import("./pages/payment/SuccessPaymentView"),
							},
							// Failed payment callback page
							{
								path: "/fail/:oid/:uid",
								lazy: () => import("./pages/payment/FailedPaymentView"),
							},
							{
								path: "/refund-policy",
								lazy: () => import("./pages/refund-policy/RefundPolicyView"),
							},
							{
								path: "*",
								lazy: () => import("./pages/not-found"),
							},
						],
					},
				],
			},
		],
	},
]);

export const Router: React.FC = () => {
	return <RouterProvider router={router} />;
};
