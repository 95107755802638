import { useEffect } from "react";
import { Typography } from "@alfalab/core-components/typography";
import { Space } from "@alfalab/core-components/space";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { ScrewdriverPaintBrushMIcon } from "@alfalab/icons-glyph/ScrewdriverPaintBrushMIcon";

import { trackScreenView } from "@/lib/metrica";

import styles from "./Error.module.css";

type CriticalErrorProps = {
	title?: string | null;
	advice?: string | null;
	action: React.ReactNode;
};

export const CriticalError: React.FC<CriticalErrorProps> = ({
	action,
	title,
	advice,
}) => {
	useEffect(() => {
		trackScreenView("Error block");
	}, []);

	return (
		<div className={styles.container}>
			<Space align="center" size="l" className={styles.content}>
				<SuperEllipse size={80}>
					<ScrewdriverPaintBrushMIcon color="var(--color-light-graphic-primary-shade-30)" />
				</SuperEllipse>

				<Space align="center" size="s">
					<Typography.Text view="primary-large" weight="bold">
						{title || "Кое-что сломалось"}
					</Typography.Text>

					{advice && (
						<Typography.Text view="primary-small">{advice}</Typography.Text>
					)}
				</Space>
			</Space>

			{action}
		</div>
	);
};
