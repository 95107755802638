import { useEffect } from "react";
import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { BottomSheet } from "@alfalab/core-components/bottom-sheet";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { Space } from "@alfalab/core-components/space";
import { ScrewdriverWrenchBoldMIcon } from "@alfalab/icons-glyph/ScrewdriverWrenchBoldMIcon";
import { ClockMIcon } from "@alfalab/icons-glyph/ClockMIcon";

import { trackScreenView } from "@/lib/metrica";

import styles from "./Error.module.css";

type NonCriticalErrorProps = {
	open: boolean;
	title?: string | null;
	advice?: string | null;
	onClose(): void;
	action: {
		label: string;
		callback(): void;
	};
};

export const NonCriticalError: React.FC<NonCriticalErrorProps> = ({
	open,
	title = null,
	advice = null,
	onClose,
	action,
}) => {
	return (
		<BottomSheet
			open={open}
			onClose={onClose}
			actionButton={
				<Button block size="s" view="secondary" onClick={action.callback}>
					{action.label}
				</Button>
			}
			initialHeight="full"
			hasCloser
			stickyHeader
			usePortal
		>
			<Space align="center" size="l" className={styles.content}>
				<SuperEllipse size={80}>
					<ScrewdriverWrenchBoldMIcon color="var(--color-light-graphic-primary-shade-30)" />
				</SuperEllipse>

				<Space align="center" size="s">
					<Typography.Text view="primary-large" weight="bold">
						{title || "Произошла ошибка"}
					</Typography.Text>

					{advice && (
						<Typography.Text view="primary-small">{advice}</Typography.Text>
					)}
				</Space>
			</Space>
		</BottomSheet>
	);
};

type InlineNonCriticalErrorProps = {
	kind?: "timeout";
	title?: string;
	message?: string;
	action: React.ReactNode;
};

export const InlineNonCriticalError: React.FC<InlineNonCriticalErrorProps> = ({
	kind,
	title,
	message,
	action,
}) => {
	useEffect(() => {
		trackScreenView("Error block", document.title, {
			content: message,
		});
	}, [message]);

	return (
		<div className={styles.container}>
			<Space align="center" size="l" className={styles.content}>
				<SuperEllipse size={80}>
					{kind === "timeout" ? (
						<ClockMIcon color="black" />
					) : (
						<ScrewdriverWrenchBoldMIcon color="black" />
					)}
				</SuperEllipse>

				<Space align="center" size="s">
					<Typography.TitleMobile tag="h1" view="small" weight="medium">
						{title}
					</Typography.TitleMobile>

					<Typography.Text view="primary-small">{message}</Typography.Text>
				</Space>
			</Space>

			{action}
		</div>
	);
};
